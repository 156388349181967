/** @jsx jsx */
import { jsx } from "theme-ui"
import { ViewElement } from "./atoms"
import { Fragment } from "react"

export const IntroText: ViewElement<IntroTextProps> = ({
  children,
  ...props
}) => {

  return (
    <Fragment>
      <div sx={{
        fontFamily: "monospace",
        fontSize: [13, null, 15],
        maxWidth: ['100%', 500],
        my: [2, null, 3]
      }}>
        {children}
      </div>
    </Fragment>
  )
}
