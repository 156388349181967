import React, { FC } from 'react';
import { Box } from 'theme-ui';
import { Link } from './nav';

interface ITwitterHandle {
  url: string
}

const parseTwitterHandleFromURL = (url: string) => {
  return `@${url.split('/').pop()}`
}

export const TwitterHandle: FC<ITwitterHandle> = ({ url }) => {
  return (
    <Box>
      <Link 
        to={`${url}`}
        variant='handle'
      >
        {parseTwitterHandleFromURL(url)}
      </Link>
    </Box>
  )
}
