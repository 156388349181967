/** @jsx jsx */
import { jsx, Heading, useThemeUI, Grid } from 'theme-ui';
import { useStaticQuery, graphql } from 'gatsby';
import { Box } from 'theme-ui';
import { ContentNode } from '../data/content';
import { ImageCard } from './atoms';
import { useLocalisedContent } from './featured';
import { Dot } from './content-page';
import { TwitterHandle } from './handles';

const SECRETARIAT_QUERY = graphql`
  query SecretariatQuery {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {regex: "//people//"},
        frontmatter: {organisations: {elemMatch: {frontmatter: {title: {
          regex: "/Progressive International Secretariat/"
        }}}}}
      }
      sort: { fields: [frontmatter___lastName], order: ASC }
    ) {
      edges {
        node {
          frontmatter {
            title
            twitter
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 500, quality: 66) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            organisations {
              frontmatter {
                title
              }
            }
            email
            countries
            role
          }
          fields {
            translations {
              childMarkdownRemark {
                ...TranslationFragment
              }
            }
          }
        }
      }
    }
  }
`

const useOrganisationStaff = () => {
  const res = useStaticQuery(SECRETARIAT_QUERY)
  
  return res.allMarkdownRemark.edges.map(e => e.node) as ContentNode[]
}

export const SecretariatGrid = () => {
  const board = useOrganisationStaff()

  return (
    <Grid gap={4} columns={[1, 2, 3]}>
      {board.map((person, i) => {
        return (
          <Box>
            <Person key={person.id} node={person} />
          </Box>
        )
      })}
    </Grid>
  )
}

const Person: React.FC<{ node: ContentNode }> = ({ node }) => {
  const localisedContent = useLocalisedContent(node)
  const { theme } = useThemeUI()
  const { email, featuredImage, title, twitter } = node.frontmatter

  return (
    <Box>
      <Box sx={{ height: [250, null, 300], background: 'linear-gradient(135deg, rgba(0, 0, 0, 0) 10%, RGBA(252, 22, 75, 0.75) 100%)' }}>
        {featuredImage ? (
          <ImageCard
            sx={{ flexGrow: 1, height: '100%' }}
            overlay={`linear-gradient(135deg, rgba(0, 0, 0, 0) 10%, RGBA(252, 22, 75, 0.75) 100%)`}
            src={featuredImage?.childImageSharp}
            position='50% 20%'
          />
        ) : (
          <Dot />
        )}
      </Box>
      <Box sx={{ my: 3 }}>
        <Heading variant='headings.5'>{title}</Heading>
        <a sx={{ textDecoration: 'none' }} href={`mailto:${email}`}>
          <Heading variant='headings.5' sx={{ color: 'accent' }}>{email}</Heading>
        </a>
      </Box>
      {twitter && (
        <TwitterHandle url={twitter} />
      )}
    </Box>
  )
}
